import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { push } from 'redux-first-history';

const initialState = {
  dailyReport: null,
  isLoading: false,
  isDeleting: false,
  error: '',
  deleteError: '',
};
export const dailyReportDetailSlice = createSlice({
  name: 'dailyReport',
  initialState: initialState,
  reducers: {
    setDailyReport: (state, { payload }) => {
      state.dailyReport = payload;
      state.isLoading = false;
      state.error = '';
    },
    setLoading: (state, payload) => {
      state.isLoading = payload;
      state.error = '';
    },
    setDeleting: (state, { payload }) => {
      state.isDeleting = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    setDeleteError: (state, { payload }) => {
      state.deleteError = payload;
      state.isDeleting = false;
    },
  },
});

export const dailyReportFetch = (day, phoneNumber) => (dispatch) => {
  dispatch(setLoading(true));
  return getApi()
    .get(`/api/float-cash/daily-reports/grouped`, {
      params: {
        day,
        phoneNumber,
      },
    })
    .then((res) => {
      const response = (res?.data?.data || [])[0];
      dispatch(setDailyReport(response));
    })
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const deleteReport = (uuids) => (dispatch) => {
  dispatch(setDeleting(true));
  dispatch(setDeleteError(''));
  const requests = [];
  for (const id of uuids) {
    requests.push(getApi().delete(`/api/float-cash/daily-reports/${id}`));
  }
  Promise.all(requests)
    .then(() => {
      dispatch(setDeleting(false));
      dispatch(push('/float-cash/daily-reports'));
    })
    .catch((err) => dispatch(setDeleteError(getApiError(err))));
};

export const {
  setDailyReport,
  setLoading,
  setDeleting,
  setError,
  setDeleteError,
} = dailyReportDetailSlice.actions;

export const dailyReportDetailReducer = dailyReportDetailSlice.reducer;
